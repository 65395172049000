import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Lottie from 'react-lottie';
import DialogContent from '@material-ui/core/DialogContent';
import * as animationDataLoading from '../../assets/loading';
import {useSelector} from 'react-redux';

function LoadingModal() {
  const miscLoading = useSelector((state) => state.misc.loading);
  const defaultLoading = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoading.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Dialog
      style={{padding: '0 !important', zIndex: 1500}}
      maxWidth={'xs'}
      open={miscLoading}
    >
      <DialogContent style={{padding: 8}}>
        <Lottie
          options={defaultLoading}
          height={150}
          width={150}
          isClickToPauseDisabled={true}
        />
      </DialogContent>
    </Dialog>
  );
}

export default LoadingModal;
