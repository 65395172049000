import React, {useState} from 'react';
import {useStyles} from './styles';
import MenuIcon from './svgs/MenuIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuDrawer from './components/MenuDrawer';
import {push} from 'connected-react-router';
import {useDispatch} from 'react-redux';
import HotMenu from './components/HotMenu';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className={classes.root}>
      <div className={classes.leftDiv}>
        <div className={classes.flexBox}>
          <IconButton onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
          <div
            className={classes.titleText}
            onClick={() => {
              if (
                window.location.pathname &&
                !window.location.pathname.includes('dashboard')
              ) {
                dispatch(push('/dashboard'));
              }
            }}
          >
            WTF Store Group
          </div>
        </div>
      </div>
      <div className={classes.rightDiv}>
        <HotMenu />
      </div>
      <MenuDrawer open={menuOpen} handleClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default Header;
