import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function PrivateAuthHoc({component: Component, ...rest}) {
  const storeData = useSelector((state) => state.auth.storeData);
  const token = storeData.token ? storeData.token : '';
  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== '' ? <Component {...prop} /> : <Redirect to={'/'} />
      }
    />
  );
}

export default PrivateAuthHoc;
