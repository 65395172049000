export const setLoading = (value) => ({
  type: 'LOADING',
  payload: value,
});

export const setSelectedStore = (value) => async (dispatch) => {
  await dispatch({type: 'SELECTED_STORE', payload: value});
};

export const setReloadAPI = (value) => ({
  type: 'RELOAD_API',
  payload: value,
});
