import * as actionTypes from '../../actionTypes';

const initialState = {
  business: {},
  menu: {},
  order: {},
  customer: {},
  auditBills: [],
  margins: [],
  wallets: [],
};

export default function dashboardReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_DASHBOARD_BUSINESS:
      return {
        ...state,
        business: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.INIT_DASHBOARD_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case actionTypes.INIT_AUDIT_BILLS:
      return {
        ...state,
        auditBills: action.payload,
      };
    case actionTypes.SAVE_MENU_MARGIN_ANALYTICS:
      return {
        ...state,
        margins: action.payload,
      };
    case actionTypes.INIT_WALLETS:
      return {
        ...state,
        wallets: action.payload,
      };
    case 'LOGOUT':
      return {
        business: {},
        menu: {},
        order: {},
        customer: {},
        auditBills: [],
        margins: [],
        wallets: [],
      };
    default:
      return state;
  }
}
