import {SvgIcon} from '@material-ui/core';
import React from 'react';

function AnalysisIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m497 482h-467v-467c0-8.291-6.709-15-15-15s-15 6.709-15 15v482c0 8.291 6.709 15 15 15h482c8.291 0 15-6.709 15-15s-6.709-15-15-15z" />
      <path d="m75 452h61c8.291 0 15-6.709 15-15v-91c0-8.291-6.709-15-15-15h-61c-8.291 0-15 6.709-15 15v91c0 8.291 6.709 15 15 15z" />
      <path d="m196 271c-8.291 0-15 6.709-15 15v151c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-151c0-8.291-6.709-15-15-15z" />
      <path d="m316 211c-8.291 0-15 6.709-15 15v211c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-211c0-8.291-6.709-15-15-15z" />
      <path d="m497 151h-61c-8.291 0-15 6.709-15 15v271c0 8.291 6.709 15 15 15h61c8.291 0 15-6.709 15-15v-271c0-8.291-6.709-15-15-15z" />
      <path d="m61.787 263.104c3.959 7.359 13.109 9.994 20.317 6.108l349.287-187.538-8.809 17.617c-3.706 7.412-.703 16.421 6.709 20.127 7.48 3.713 16.436.652 20.127-6.709l30-60c2.329-4.644 2.08-10.166-.659-14.59s-7.559-7.119-12.759-7.119h-75c-8.291 0-15 6.709-15 15s6.709 15 15 15h15.5l-338.604 181.787c-7.295 3.941-10.035 13.037-6.109 20.317z" />
    </SvgIcon>
  );
}

export default AnalysisIcon;
