import React from 'react';
import PropTypes from 'prop-types';
import {useStyles} from './styles';

function MenuSelection(props) {
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${props.active && classes.activeRoot}`}
      onClick={props.handleClick}
    >
      <div className={classes.flexBox}>
        {props.children && (
          <div className={classes.labelIcon}>{props.children}</div>
        )}
        <div>{props.label}</div>
      </div>
    </div>
  );
}

MenuSelection.propType = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default MenuSelection;
