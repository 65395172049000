import * as actionTypes from '../../actionTypes';

const initialState = {
  apiLoading: false,
  loginErr: false,
  storeData: {},
  storeDetails: [],
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginErr: action.payload,
      };
    case actionTypes.REST_DATA:
      return {
        ...state,
        storeData: action.payload,
      };
    case actionTypes.API_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
      };
    case actionTypes.STORE_DETAILS:
      return {
        ...state,
        storeDetails: action.payload.result,
      };
    case actionTypes.LOG_OUT:
      window.localStorage.clear();
      return {
        ...state,
        storeData: {},
      };
    default:
      return state;
  }
}
