import React from 'react';
import {useStyles} from '../styles';
import RefreshIcon from '@material-ui/icons/SyncRounded';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import {useDispatch} from 'react-redux';
import {setReloadAPI, logout} from '../../../services/actions';
import StoreSelection from './StoreSelection';
import {useHistory} from 'react-router';

function HotMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const icons = [
    {
      title: 'Refresh',
      component: <RefreshIcon />,
      handleClick: () => dispatch(setReloadAPI('reload called')),
    },
    {
      title: 'Logout',
      component: <PowerSettingsNewIcon />,
      handleClick: () => dispatch(logout()),
    },
  ];
  return (
    <div className={classes.iconFlex}>
      {(history.location.pathname === '/analytics' ||
        history.location.pathname.includes('reports')) && <StoreSelection />}
      {icons.map((icon, index) => (
        <IconButton key={index} onClick={icon.handleClick}>
          <Badge
            className={classes.badge}
            badgeContent={icon.count ? icon.count : 0}
          >
            {icon.component}
          </Badge>
        </IconButton>
      ))}
    </div>
  );
}

export default HotMenu;
