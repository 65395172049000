import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';

export const getDashboardAnalytics = (from, to, storeID) => {
  return (dispatch, getState) => {
    const id = getState().auth.storeData._id;
    dispatch(setLoading(true));
    let url = `${API_URL}/store_groups/${id}/analytics?from=${from}&to=${to}`;
    if (storeID) {
      url += `&id=${storeID}`;
    }
    http
      .get(url)
      .then((res) => {
        if (res.data.bussiness) {
          dispatch({
            type: actionTypes.INIT_DASHBOARD_BUSINESS,
            payload: res.data.bussiness,
          });
        }
        if (res.data.menu) {
          dispatch({
            type: actionTypes.INIT_DASHBOARD_MENU,
            payload: res.data.menu,
          });
        }
        if (res.data.likedDislkedItems) {
          if (res.data.likedDislkedItems.mostLiked) {
            dispatch({
              type: 'SAVE_LIKED_ITEMS',
              payload: res.data.likedDislkedItems.mostLiked,
            });
          }
          if (res.data.likedDislkedItems.mostDisliked) {
            dispatch({
              type: 'SAVE_DISLIKED_ITEMS',
              payload: res.data.likedDislkedItems.mostDisliked,
            });
          }
        }
        if (res.data.mostOrderedCategory) {
          dispatch({
            type: actionTypes.SAVE_CATEGORY_ANALYTICS,
            payload: res.data.mostOrderedCategory,
          });
        }
        if (res.data.orders) {
          dispatch({
            type: actionTypes.INIT_DASHBOARD_ORDER,
            payload: res.data.orders,
          });
        }
        if (res.data.customer) {
          dispatch({
            type: actionTypes.INIT_DASHBOARD_CUSTOMER,
            payload: res.data.customer,
          });
        }
        if (res.data.auditBills) {
          dispatch({
            type: actionTypes.INIT_AUDIT_BILLS,
            payload: res.data.auditBills,
          });
        }
        if (res.data.wallets) {
          dispatch({type: actionTypes.INIT_WALLETS, payload: res.data.wallets});
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};
