import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ManageItemsIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.04248 13.9084C5.51361 13.605 5.15625 13.0351 5.15625 12.3828C5.15625 9.6904 7.34665 7.5 10.0391 7.5H12.0512L12.146 5.9375H0.744629L1.56357 19.4495C1.58234 19.7588 1.83853 20 2.14844 20H5.74371C5.37506 19.51 5.15625 18.9012 5.15625 18.2422C5.15625 17.4825 5.64087 16.8338 6.31714 16.5889C6.07742 16.2213 5.9375 15.7831 5.9375 15.3125C5.9375 14.8415 6.07773 14.4028 6.3179 14.0349C6.12686 13.9919 6.02081 13.9496 6.04248 13.9084Z" />
      <path d="M6.4447 4.76578C6.44516 4.76578 6.44562 4.76562 6.44608 4.76562H12.3047C12.6283 4.76562 12.8906 4.50333 12.8906 4.17969C12.8906 3.85605 12.6283 3.59375 12.3047 3.59375H7.52701L9.10645 1.17188H11.1328C11.4565 1.17188 11.7188 0.909576 11.7188 0.585938C11.7188 0.262299 11.4565 0 11.1328 0H8.78906C8.591 0 8.40637 0.0999451 8.29834 0.265808L6.12793 3.59375H0.585938C0.262299 3.59375 0 3.85605 0 4.17969C0 4.50333 0.262299 4.76562 0.585938 4.76562H6.44257C6.44333 4.76562 6.44409 4.76578 6.4447 4.76578Z" />
      <path d="M6.32812 12.3828C6.32812 12.7065 6.59042 12.9688 6.91406 12.9688H19.4141C19.7377 12.9688 20 12.7065 20 12.3828C20 10.3366 18.3353 8.67188 16.2891 8.67188H10.0391C7.99286 8.67188 6.32812 10.3366 6.32812 12.3828Z" />
      <path d="M8.28125 14.1406C7.63397 14.1406 7.10938 14.6652 7.10938 15.3125C7.10938 15.9598 7.63397 16.4844 8.28125 16.4844H18.0469C18.6942 16.4844 19.2188 15.9598 19.2188 15.3125C19.2188 14.6652 18.6942 14.1406 18.0469 14.1406H8.28125Z" />
      <path d="M19.4141 17.6562H6.91406C6.59042 17.6562 6.32812 17.9185 6.32812 18.2422C6.32812 19.2114 7.1167 20 8.08594 20H18.2422C19.2114 20 20 19.2114 20 18.2422C20 17.9185 19.7377 17.6562 19.4141 17.6562Z" />
    </SvgIcon>
  );
}

export default ManageItemsIcon;
