const initState = {
  categoryList: [],
  itemList: [],
  categoryFor: {id: 'all', name: 'All'},
  salesList: [],
  topItemList: [],
  hatedItemList: [],
  itemCount: [],
  rushHours: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SAVE_ORDER_ANALYTICS':
      return {
        ...state,
        rushHours: action.payload,
      };
    case 'SAVE_CATEGORY_ANALYTICS':
      return {
        ...state,
        categoryList: action.payload,
      };
    case 'SAVE_ITEMS_ANALYTICS':
      return {
        ...state,
        itemList: action.payload,
      };
    case 'SAVE_LIKED_ITEMS':
      return {
        ...state,
        topItemList: action.payload,
      };
    case 'SAVE_DISLIKED_ITEMS':
      return {
        ...state,
        hatedItemList: action.payload,
      };
    case 'SAVE_SALES_ANALYTICS':
      return {
        ...state,
        salesList: action.payload.total,
      };
    case 'ITEMS_ANALYTICS_FOR':
      return {
        ...state,
        categoryFor: action.payload,
      };
    case 'SAVE_ITEM_COUNT':
      return {
        ...state,
        itemCount: action.payload,
      };
    case 'LOGOUT':
      return {
        categoryList: [],
        itemList: [],
        categoryFor: {id: 'all', name: 'All'},
        salesList: [],
        topItemList: [],
        hatedItemList: [],
        itemCount: [],
        rushHours: [],
      };
    default:
      return state;
  }
};

export default reducer;
