import moment from 'moment';

export const API_URL = process.env.REACT_APP_API_URL;
export const S3_URL = process.env.REACT_APP_S3_URL;
export const ABLY_KEY = process.env.REACT_APP_ABLY_KEY;
export const ITEM_UPLOAD_TEMPLATE_URL =
  'https://d3f679ocn1f9ln.cloudfront.net/WTF%20upload%20template.xlsm';
export const CLOUD_FRONT_ICONS = 'https://d3f679ocn1f9ln.cloudfront.net/icons/';

export const getDateFormat = (from, to) => {
  let temp = '';
  if (moment(from).isSame(moment(to), 'date')) {
    if (moment().isSame(moment(from), 'date')) {
      temp = `Today ${moment(to).format('MMM DD, YYYY')}`;
    } else if (moment().subtract(1, 'day').isSame(moment(from), 'date')) {
      temp = `Yesterday ${moment(to).format('MMM DD, YYYY')}`;
    } else {
      temp = `${moment(from).format('MMM DD, YYYY')}`;
    }
  } else if (
    moment(from).isSame(moment().subtract(7, 'days'), 'date') &&
    moment(to).isSame(moment(), 'date')
  ) {
    temp = `Last week ${moment(from).format('MMM DD')} - ${moment(to).format(
      'MMM DD, YYYY'
    )}`;
  } else {
    temp = `${moment(from).format('MMM DD')} - ${moment(to).format(
      'MMM DD, YYYY'
    )}`;
  }
  return temp;
};

export const chartColors = [
  '#FBC02D',
  '#64C3D1',
  '#EC8686',
  '#5D9ED3',
  '#CB7CC0',
  '#98B433',
  '#9785C2',
  '#E66E50',
  '#C2B89D',
  '#C4C4C4',
  '#FFCC66',
  '#6699CC',
  '#663366',
  '#9999CC',
  '#CCCCCC',
  '#669999',
  '#CCCC66',
  '#CC6600',
  '#9999FF',
  '#0066CC',
  '#99CCCC',
  '#999999',
  '#FFCC00',
  '#009999',
  '#99CC33',
  '#FF9900',
  '#999966',
  '#66CCCC',
  '#339966',
  '#CCCC33',
  '#003f5c',
  '#665191',
  '#a05195',
  '#d45087',
  '#2f4b7c',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#EF6F6C',
  '#465775',
  '#56E39F',
  '#59C9A5',
  '#5B6C5D',
  '#0A2342',
  '#2CA58D',
  '#84BC9C',
  '#CBA328',
  '#F46197',
  '#DBCFB0',
  '#545775',
];
