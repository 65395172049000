import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import {toast} from 'react-toastify';

export const initSales = (list) => {
  return {
    type: 'INIT_SALES',
    payload: list,
  };
};

export const getSalesInfo = (
  fromDate,
  toDate,
  paymentMode,
  walletTypes,
  orderType,
  deleted
) => {
  return (dispatch, getState) => {
    const restaurantID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    let url = `${API_URL}/stores/${restaurantID}/orders?from=${fromDate}&to=${toDate}&limit=1000&page=1`;
    if (paymentMode && paymentMode.trim() !== '') {
      url += `&payment_modes=${paymentMode}`;
    }
    if (walletTypes && walletTypes.trim() !== '') {
      url += `&wallet_type=${walletTypes}`;
    }
    if (orderType) {
      url += `&order_types=${orderType}`;
    }
    if (deleted) {
      url += '&deleted=true';
    }
    http
      .get(url)
      .then((res) => {
        dispatch(initSales(res.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Unable to Retrieve Data for given Sales Date');
        dispatch(setLoading(false));
      });
  };
};

export const downloadCSV = (fromDate, toDate) => {
  return (dispatch, getState) => {
    const restaurantID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    // const restaurantID = getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    console.log('download clicked');
    http
      .get(
        `${API_URL}/store_groups/${restaurantID}/sales?from=${fromDate}&to=${toDate}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `sales report (${new Date().toDateString()}).csv`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data for given sales date');
        dispatch(setLoading(false));
      });
  };
};

export const getGCategoriesConfig = () => {
  return (dispatch, getState) => {
    const restaurantID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/grouped/category?id=${restaurantID}`)
      .then((res) => {
        dispatch({type: 'INIT_GROUPED_CATEGORY', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const addGCategoriesConfig = (data, cb) => {
  return (dispatch, getState) => {
    const restaurantID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/grouped/category?id=${restaurantID}`, data)
      .then((res) => {
        toast.success('added successfully!');
        cb();
        dispatch(getGCategoriesConfig());
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to add');
        dispatch(setLoading(false));
      });
  };
};

export const deleteGCategoriesConfig = (id) => {
  return (dispatch, getState) => {
    const restaurantID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/grouped/category/${id}?id=${restaurantID}`)
      .then((res) => {
        toast.success('deleted successfully!');
        dispatch(getGCategoriesConfig());
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to delete');
        dispatch(setLoading(false));
      });
  };
};

export const getGroupedCategoryReport = (from, to, groupID) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${storeID}/grouped/category/sales?from=${from}&to=${to}&group_id=${groupID}`
      )
      .then((res) => {
        dispatch({type: 'INIT_GROUPED_CATEGORY_REPORT', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadGroupedCategoryReport = (from, to, groupID) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${storeID}/grouped/category/sales?from=${from}&to=${to}&group_id=${groupID}&type=download`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `grouped category (${new Date().toDateString()}).xls`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const getAuditBills = (from, to) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    let url = `${API_URL}/stores/${storeID}/audit_bills`;
    if (from && to) {
      url += `?from=${from}&to=${to}`;
    }
    http
      .get(url)
      .then((res) => {
        dispatch({type: 'INIT_AUDIT_BILLS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get bills/kots');
        dispatch(setLoading(false));
      });
  };
};

export const getCategoryWiseSales = (from, to) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    http
      .get(`${API_URL}/stores/${storeID}/category/sales?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({type: 'INIT_CATEGORY_WISE_SALES', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get sales');
        dispatch(setLoading(false));
      });
  };
};

export const getCustomerCredits = () => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    http
      .get(`${API_URL}/khata?id=${storeID}`)
      .then((res) => {
        dispatch({type: 'INIT_CUSTOMER_CREDITS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get credits');
        dispatch(setLoading(false));
      });
  };
};

export const getShortSummary = (from, to) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    http
      .get(`${API_URL}/stores/${storeID}/short_summary?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({type: 'INIT_SHORT_SUMMARY', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to get summary');
        dispatch(setLoading(false));
      });
  };
};

export const settleCustomerCredit = (id, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/khata/${id}`)
      .then((res) => {
        if (cb) {
          cb();
        }
        dispatch(getCustomerCredits());
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error('Unable to settle credits');
        dispatch(setLoading(false));
      });
  };
};

export const clearSalesInfo = () => {
  return (dispatch) => {
    dispatch(initSales([]));
  };
};

export const deleteSalesReport = (id) => {
  return (dispatch) => {
    return new Promise((resolve, rej) => {
      dispatch(setLoading(true));
      http
        .delete(`${API_URL}/orders/${id}`, {
          data: {
            visible: false,
            reason: 'Delete sales Data',
          },
        })
        .then((res) => {
          dispatch({type: 'SALES_DELETE', payload: res.data});
          dispatch(setLoading(false));
          dispatch(getSalesCountInfo());
          resolve();
        })
        .catch((err) => {
          toast.error('Unable to Delete Data');
          dispatch(setLoading(false));
          rej(err);
        });
    });
  };
};

export const getSalesCountInfo = () => {
  return (dispatch, getState) => {
    const restaurantID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${restaurantID}/orders/details`)
      .then((res) => {
        dispatch({type: 'UPDATE_SALES_COUNT', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Unable to Retrive Data');
        dispatch(setLoading(false));
      });
  };
};

export const getPayoutInfo = (from, to) => {
  return (dispatch, getState) => {
    const restaurantID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${restaurantID}/ordersPayment?from=${from}&to=${to}`
      )
      .then((res) => {
        dispatch({type: 'INIT_PAYOUT', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to retrive data for given sales date');
        dispatch(setLoading(false));
      });
  };
};

export const getBillWiseTax = (from, to) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${storeID}/detailed/bill?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({type: 'INIT_BILL_WISE_TAX_REPORT', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadBillWiseTax = (from, to) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${storeID}/detailed/bill?from=${from}&to=${to}&type=download`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `bill wise (${new Date().toDateString()}).xls`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const getConsolidatedTax = (from, to) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${storeID}/consolidated/tax?from=${from}&to=${to}`
      )
      .then((res) => {
        dispatch({type: 'INIT_CONSOLIDATED_TAX_REPORT', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadConsolidatedTax = (from, to) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${storeID}/consolidated/tax?from=${from}&to=${to}&type=download`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `consolidated tax (${new Date().toDateString()}).xls`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const getPaymentSummary = (from, to) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${storeID}/ordersPayment?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({type: 'INIT_PAYMENT_SUMMARY_REPORT', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadPaymentSummary = (from, to) => {
  return (dispatch, getState) => {
    const storeID =
      getState().misc.selectedStore && getState().misc.selectedStore._id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${storeID}/consolidated/tax?from=${from}&to=${to}&type=download`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `consolidated tax (${new Date().toDateString()}).xls`
        );
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
};
