import React from 'react';
import Header from '../../modules/Header';

const headerHoc = (WrapComponent) => {
  function HocContent() {
    return (
      <>
        <Header />
        <WrapComponent />
      </>
    );
  }
  return HocContent;
};

export default headerHoc;
