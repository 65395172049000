import {createMuiTheme} from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    baseColor: '#FEBF2C',
    primary: {main: '#212A39'},
    secondary: {main: 'rgba(0, 0, 0, 0.36)'},
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: 'rgba(33, 42, 57, 0.87)',
      secondary: 'rgba(33, 42, 57, 0.5)',
    },
    border: {
      default: '1px solid rgba(0, 0, 0, 0.07)',
    },
    shadow: '0px 1px 2px rgba(0, 0, 0, 0.09)',
    accordian: {
      border: 'rgba(0, 0, 0, 0.12)',
    },
    mustTry: {
      background: '#FFFBEF',
    },
  },
  spacing: 2,
  homepage: {
    background: '#F1F5FF',
    color: '#C1C1C1',
  },
  tip: {
    boxShadow: 'rgba(0,0,0,0.1)',
  },
  typography: {
    fontFamily: ['Inter'].join(','),
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    baseColor: '#FEBF2C',
    primary: {main: 'rgba(255,255,255, 0.87)'},
    secondary: {main: 'rgba(255,255,255, 0.36)'},
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
    border: {
      default: '1px solid rgba(255, 255, 255, 0.17)',
    },
    shadow: '0px 1px 2px rgba(0, 0, 0, 0.79)',
    accordian: {
      border: 'rgba(255, 255, 255, 0.12)',
    },
    mustTry: {
      background: '#A3A29F',
    },
  },
  spacing: 2,
  homepage: {
    background: '#5D5D5E',
    color: '#C1C1C1',
  },
  tip: {
    boxShadow: 'rgba(255,255,255,0.1)',
  },
  typography: {
    fontFamily: ['Inter'].join(','),
  },
});
