import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function DashboardIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.70828 0H1.45828C0.654144 0 0 0.654144 0 1.45828V5.20828C0 6.01257 0.654144 6.66672 1.45828 6.66672H7.70828C8.51257 6.66672 9.16672 6.01257 9.16672 5.20828V1.45828C9.16672 0.654144 8.51257 0 7.70828 0Z" />
      <path d="M7.70828 8.33328H1.45828C0.654144 8.33328 0 8.98743 0 9.79172V18.5417C0 19.3459 0.654144 20 1.45828 20H7.70828C8.51257 20 9.16672 19.3459 9.16672 18.5417V9.79172C9.16672 8.98743 8.51257 8.33328 7.70828 8.33328Z" />
      <path d="M18.5417 13.3333H12.2917C11.4874 13.3333 10.8333 13.9874 10.8333 14.7917V18.5417C10.8333 19.3459 11.4874 20 12.2917 20H18.5417C19.3458 20 20 19.3459 20 18.5417V14.7917C20 13.9874 19.3458 13.3333 18.5417 13.3333Z" />
      <path d="M18.5417 0H12.2917C11.4874 0 10.8333 0.654144 10.8333 1.45828V10.2083C10.8333 11.0126 11.4874 11.6667 12.2917 11.6667H18.5417C19.3458 11.6667 20 11.0126 20 10.2083V1.45828C20 0.654144 19.3458 0 18.5417 0V0Z" />
    </SvgIcon>
  );
}

export default DashboardIcon;
