const initialState = {
  orderList: [],
};

const purchaseOrderReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_PURCHASE_ORDER':
      return {
        ...state,
        orderList: action.payload,
      };
    case 'LOGOUT':
      return {
        orderList: [],
      };
    default:
      return state;
  }
};

export default purchaseOrderReducers;
