import axios from 'axios';
import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {push} from 'connected-react-router';

export const login = (user, pwd) => {
  return (dispatch) => {
    dispatch({type: actionTypes.API_LOADING, payload: true});
    axios
      .post(`${API_URL}/store_groups/auth/login`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        await window.localStorage.setItem('auth_token', res.data.token);
        await window.localStorage.setItem(
          'refresh_token',
          res.data.refreshToken
        );
        await dispatch({type: actionTypes.REST_DATA, payload: res.data});
        await dispatch({type: actionTypes.API_LOADING, payload: false});
        await dispatch(getStoreData(res.data._id));
        await dispatch(push('/dashboard'));
      })
      .catch(() => {
        dispatch({type: actionTypes.API_LOADING, payload: false});
        dispatch(loginError(true));
      });
  };
};

export const getStoreData = (id) => {
  return (dispatch) => {
    http
      .get(`${API_URL}/store_groups/${id}/stores?limit=30`)
      .then((res) => {
        dispatch({type: actionTypes.STORE_DETAILS, payload: res.data});
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const loginError = (value) => ({
  type: actionTypes.LOGIN_ERROR,
  payload: value,
});

export const logout = () => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOG_OUT});
  };
};
