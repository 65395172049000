import React, {lazy, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {lightTheme, darkTheme} from './utils/themes/theme';
import './App.scss';
import {Switch} from 'react-router';

import suspenseHoc from './hoc/suspenseHoc';
import PrivateAuthHoc from './hoc/PrivateAuthHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import headerHoc from './hoc/headerHoc';
import LoadingModal from './modules/LoadingModal';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LandingPage = lazy(() => import('./pages/LandingPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const ManageItemsPage = lazy(() => import('./pages/ManageItemsPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const NewDashboardPage = lazy(() => import('./pages/NewDashboardPage'));
const PurchaseOrderPage = lazy(() => import('./pages/PurchaseOrderPage'));

function App() {
  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '0.5.01g');
      } else if (appVersion !== '0.5.01g') {
        toast.info('updating new version');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '0.5.01g');
          window.location.reload();
        }, 2000);
      }
    };
  }, []);
  const theme = window.localStorage.theme ? window.localStorage.theme : 'light';
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <Switch>
        <PrivateAuthHoc
          exact
          path="/dashboard"
          component={suspenseHoc(headerHoc(DashboardPage))}
        />
        <PrivateAuthHoc
          exact
          path="/manage-items"
          component={suspenseHoc(headerHoc(ManageItemsPage))}
        />
        <PrivateAuthHoc
          exact
          path="/analytics"
          component={suspenseHoc(headerHoc(NewDashboardPage))}
        />
        <PrivateAuthHoc
          exact
          path="/purchase-order"
          component={suspenseHoc(headerHoc(PurchaseOrderPage))}
        />
        <PrivateAuthHoc
          path="/reports"
          component={suspenseHoc(headerHoc(ReportsPage))}
        />
        <LandingAuthHoc path="/" component={suspenseHoc(LandingPage)} />
      </Switch>
      <LoadingModal />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
    </ThemeProvider>
  );
}

export default App;
