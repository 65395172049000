const initialState = {
  reloadAPI: '',
  loading: false,
  selectedStore: {},
  federated: false,
};

const miscReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'RELOAD_API':
      return {
        ...state,
        reloadAPI: action.payload,
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SELECTED_STORE':
      return {
        ...state,
        selectedStore: action.payload,
      };
    case 'SET_FEDERATED':
      return {
        ...state,
        federated: action.payload,
      };
    case 'LOGOUT':
      return {
        reloadAPI: '',
        loading: false,
        selectedStore: {},
        federated: false,
      };
    default:
      return state;
  }
};

export default miscReducers;
