import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MenuIcon() {
  return (
    <SvgIcon
      width="24"
      height="16"
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999999 0C0.447714 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H11.5714C12.1237 2 12.5714 1.55228 12.5714 1C12.5714 0.447715 12.1237 0 11.5714 0H0.999999Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7C0.447716 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9H23C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7H1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 14C0.447715 14 0 14.4477 0 15C0 15.5523 0.447715 16 1 16H17.2857C17.838 16 18.2857 15.5523 18.2857 15C18.2857 14.4477 17.838 14 17.2857 14H1Z"
      />
    </SvgIcon>
  );
}

export default MenuIcon;
