import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function SalesReportIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.0417 0H3.95841C2.69425 0 1.66675 1.0275 1.66675 2.29167V17.7083C1.66675 18.9725 2.69425 20 3.95841 20H16.0417C17.3059 20 18.3334 18.9725 18.3334 17.7083V2.29167C18.3334 1.0275 17.3059 0 16.0417 0ZM8.95842 10.8333V6.73C10.9584 7.03416 12.5001 8.75 12.5001 10.8333C12.5001 13.1308 10.6309 15 8.33342 15C7.41008 15 6.56425 14.6883 5.87258 14.1775L8.77508 11.275C8.89258 11.1583 8.95842 10.9992 8.95842 10.8333ZM7.70842 6.73V10.575L4.98925 13.2942C4.47841 12.6025 4.16675 11.7567 4.16675 10.8333C4.16675 8.75 5.70842 7.03416 7.70842 6.73ZM15.2084 17.7083H4.79175C4.44675 17.7083 4.16675 17.4283 4.16675 17.0833C4.16675 16.7383 4.44675 16.4583 4.79175 16.4583H15.2084C15.5534 16.4583 15.8334 16.7383 15.8334 17.0833C15.8334 17.4283 15.5534 17.7083 15.2084 17.7083ZM15.2084 15H13.9584C13.6134 15 13.3334 14.72 13.3334 14.375C13.3334 14.03 13.6134 13.75 13.9584 13.75H15.2084C15.5534 13.75 15.8334 14.03 15.8334 14.375C15.8334 14.72 15.5534 15 15.2084 15ZM15.2084 12.7083H13.9584C13.6134 12.7083 13.3334 12.4283 13.3334 12.0833C13.3334 11.7383 13.6134 11.4583 13.9584 11.4583H15.2084C15.5534 11.4583 15.8334 11.7383 15.8334 12.0833C15.8334 12.4283 15.5534 12.7083 15.2084 12.7083ZM15.2084 10.2083H13.9584C13.6134 10.2083 13.3334 9.92833 13.3334 9.58333C13.3334 9.23833 13.6134 8.95833 13.9584 8.95833H15.2084C15.5534 8.95833 15.8334 9.23833 15.8334 9.58333C15.8334 9.92833 15.5534 10.2083 15.2084 10.2083ZM15.2084 7.91666H13.9584C13.6134 7.91666 13.3334 7.63666 13.3334 7.29166C13.3334 6.94666 13.6134 6.66666 13.9584 6.66666H15.2084C15.5534 6.66666 15.8334 6.94666 15.8334 7.29166C15.8334 7.63666 15.5534 7.91666 15.2084 7.91666Z" />
    </SvgIcon>
  );
}

export default SalesReportIcon;
