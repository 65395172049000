import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import miscReducers from './miscReducers';
import analyticsReducers from './analyticsReducers';
import itemReducers from './itemReducers';
import dashboardReducers from './dashboardReducers';
import purchaseOrderReducers from './purchaseOrderReducers';
import salesReducers from './salesReducers';
import payoutReducers from './payoutReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    misc: miscReducers,
    analytics: analyticsReducers,
    item: itemReducers,
    dashboard: dashboardReducers,
    po: purchaseOrderReducers,
    salesReport: salesReducers,
    payout: payoutReducers,
  });

export default reducers;
