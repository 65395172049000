import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: `0px 0px 3px ${theme.palette.text.secondary}`,
  },
  leftDiv: {
    paddingLeft: theme.spacing(6),
  },
  rightDiv: {
    paddingRight: theme.spacing(12),
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 18,
    fontWeight: 600,
    marginLeft: theme.spacing(6),
    cursor: 'pointer',
  },
  drawerRoot: {
    width: theme.spacing(140),
  },
  iconFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    '& > span': {
      backgroundColor: theme.palette.baseColor,
      color: '#1F212B',
    },
  },
  drawerTitle: {
    fontSize: 20,
    fontWeight: 600,
    margin: 16,
  },
}));
