import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import {useStyles} from '../styles';
import {useRouteMatch} from 'react-router';
import MenuSelection from '../../MenuSelection';
import DashboardIcon from '../svgs/DahboardIcon';
import LogoutIcon from '../svgs/LogoutIcon';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {logout} from '../../../services/actions/authActions';
import AnalysisIcon from '../svgs/AnalysisIcon';
import SalesReportIcon from '../svgs/SalesReportIcon';
import ManageItemsIcon from '../svgs/ManageItemsIcon';
import PurchaseIcon from '../svgs/PurchaseIcon';

function MenuDrawer({open, handleClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const menus = [
    {
      label: 'Dashboard',
      active: useRouteMatch('/dashboard'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/dashboard'));
      },
      icon: <DashboardIcon />,
    },
    {
      label: 'Manage Items',
      active: useRouteMatch('/manage-items'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/manage-items'));
      },
      icon: <ManageItemsIcon />,
    },
    {
      label: 'Analytics',
      active: useRouteMatch('/analytics'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/analytics'));
      },
      icon: <AnalysisIcon />,
    },
    {
      label: 'Purchase order',
      active: useRouteMatch('/purchase-order'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/purchase-order'));
      },
      icon: <PurchaseIcon />,
    },
    {
      label: 'Reports',
      active: useRouteMatch('/reports'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/reports'));
      },
      icon: <SalesReportIcon />,
    },
    {
      label: 'Logout',
      active: false,
      handleMenu: () => {
        handleClose();
        dispatch(logout());
      },
      icon: <LogoutIcon />,
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        <div className={classes.drawerTitle}>
          Welcome to Store group dashboard
        </div>
        {menus.map((menu, index) => (
          <MenuSelection
            key={index}
            active={menu.active}
            label={menu.label}
            handleClick={menu.handleMenu}
          >
            {menu.icon}
          </MenuSelection>
        ))}
      </div>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MenuDrawer;
