const initialState = {
  list: [],
  storePayout: [],
};

export default function salesReportReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_PAYOUT':
      return {
        ...state,
        list: action.payload,
      };
    case 'INIT_STORE_PAYOUT':
      return {
        ...state,
        storePayout: action.payload,
      };
    case 'UPDATE_SALES_COUNT':
      return {
        ...state,
        salesCount: action.payload,
      };
    case 'LOGOUT':
      return {
        list: [],
        storePayout: [],
      };
    default:
      return state;
  }
}
