import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedStore} from '../../../services/actions/miscActions';

function StoreSelection() {
  const dispatch = useDispatch();

  const storeList = useSelector((state) => state.auth.storeDetails);
  const selectedStore = useSelector((state) => state.misc.selectedStore);

  const handleChange = (value) => {
    if (value) {
      const index = storeList.findIndex((a) => a._id === value);
      dispatch(setSelectedStore(storeList[index]));
    } else {
      dispatch(setSelectedStore({}));
    }
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      size={'small'}
      style={{minWidth: 200}}
    >
      <InputLabel id="wtf-select-store">{'Store'}</InputLabel>
      <Select
        size={'small'}
        labelId="wtf-select-store"
        value={selectedStore._id ? selectedStore._id : ''}
        onChange={(e) => handleChange(e.target.value)}
        label={'Store'}
      >
        <MenuItem key={0} value={''}>
          All
        </MenuItem>
        {storeList.map((obj, index) => (
          <MenuItem key={index + 1} value={obj._id}>
            {obj.name.en}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default StoreSelection;
