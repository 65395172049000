import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function LogoutIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.4999 10.8334C12.0391 10.8334 11.6666 11.2068 11.6666 11.6667V15.0001C11.6666 15.4592 11.2932 15.8334 10.8332 15.8334H8.33319V3.3335C8.33319 2.62183 7.87986 1.98601 7.19826 1.74934L6.95153 1.66679H10.8332C11.2932 1.66679 11.6666 2.04094 11.6666 2.50022V5.0002C11.6666 5.46009 12.0391 5.83347 12.4999 5.83347C12.9607 5.83347 13.3331 5.46009 13.3331 5.0002V2.50022C13.3331 1.12191 12.2115 0.000244141 10.8332 0.000244141H1.87498C1.84324 0.000244141 1.81669 0.0144347 1.78587 0.0185545C1.74574 0.0151976 1.70744 0.000244141 1.6667 0.000244141C0.74752 0.000244141 0 0.747613 0 1.66679V16.6667C0 17.3783 0.453334 18.0142 1.13494 18.2508L6.14999 19.9226C6.31997 19.975 6.48904 20.0001 6.66665 20.0001C7.58583 20.0001 8.33319 19.2525 8.33319 18.3334V17.5001H10.8332C12.2115 17.5001 13.3331 16.3784 13.3331 15.0001V11.6667C13.3331 11.2068 12.9607 10.8334 12.4999 10.8334Z" />
      <path d="M19.7557 7.74429L16.4223 4.41105C16.1841 4.17271 15.8257 4.10099 15.5141 4.23008C15.2033 4.35932 14.9999 4.66342 14.9999 5.00018V7.50016H11.6667C11.2066 7.50016 10.8333 7.87338 10.8333 8.33343C10.8333 8.79348 11.2066 9.1667 11.6667 9.1667H14.9999V11.6667C14.9999 12.0034 15.2033 12.3075 15.5141 12.4368C15.8257 12.5659 16.1841 12.4942 16.4223 12.256L19.7557 8.92257C20.0815 8.59679 20.0815 8.07007 19.7557 7.74429Z" />
    </SvgIcon>
  );
}

export default LogoutIcon;
