import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PurchaseIcon() {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.625 13.875H3.375V14.625H2.625V13.875Z" />
      <path d="M0.75 15.375H1.5V16.125H0.75V15.375Z" />
      <path d="M2.25 4.5H6.75V5.25H2.25V4.5Z" />
      <path d="M0.75 4.5H1.5V5.25H0.75V4.5Z" />
      <path d="M3 6H3.75V6.75H3V6Z" />
      <path d="M20.8624 9.8205C21.9338 11.2091 22.5 12.8704 22.5 14.625C22.5 18.9671 18.9675 22.5 14.625 22.5C10.7055 22.5 7.3515 19.5761 6.82238 15.6994C6.79725 15.5134 6.63862 15.375 6.45112 15.375H2.25V16.125H6.13013C6.84713 20.2095 10.4423 23.25 14.625 23.25C19.3808 23.25 23.25 19.3811 23.25 14.625C23.25 12.7031 22.6297 10.8829 21.456 9.36188L20.8624 9.8205Z" />
      <path d="M7.875 14.625C7.875 18.3469 10.9031 21.375 14.625 21.375C18.3469 21.375 21.375 18.3469 21.375 14.625C21.375 11.8335 19.6714 9.432 17.2493 8.406L18.6401 7.01513C18.7106 6.945 18.75 6.84975 18.75 6.75V1.125H18.7466C18.7466 1.07662 18.741 1.02788 18.7215 0.981375C18.6634 0.841125 18.5265 0.75 18.375 0.75H10.875C10.7756 0.75 10.68 0.789375 10.6099 0.859875L9.11025 2.3595C9.042 2.42737 9 2.52112 9 2.625V6H4.5V6.75H9V8.09025C7.29413 9.55987 6.2265 11.6411 6.03225 13.875H4.125V14.625H6.38325C6.58387 14.625 6.74887 14.4671 6.75787 14.2669C6.85538 12.0877 7.86413 10.0399 9.528 8.625H11.5466C9.37013 9.74662 7.875 12.0124 7.875 14.625ZM17.25 7.34475V2.78025L18 2.03025V6.59475L17.25 7.34475ZM16.7197 2.25H14.0303L14.7803 1.5H17.4697L16.7197 2.25ZM11.0302 1.5H13.7197L12.9697 2.25H10.2802L11.0302 1.5ZM9.75 7.875V3H12.75V4.875H13.5V3H16.5V7.875H9.75ZM11.3077 9.62963L12.048 10.9114L12.6975 10.5364L11.9591 9.25725C12.6574 8.90888 13.431 8.69475 14.25 8.64375V10.125H15V8.64412C15.819 8.69513 16.5926 8.90963 17.2909 9.25763L16.5506 10.5394L17.2001 10.9144L17.9419 9.62963C18.6064 10.0725 19.1775 10.6432 19.62 11.3077L18.3349 12.0495L18.7099 12.699L19.992 11.9587C20.34 12.657 20.5545 13.4306 20.6055 14.2496H19.125V14.9996H20.6059C20.5549 15.8186 20.3404 16.5923 19.9924 17.2905L18.7103 16.5502L18.3353 17.1998L19.6204 17.9415C19.1775 18.606 18.6068 19.1771 17.9423 19.6196L17.2005 18.3349L16.551 18.7099L17.2913 19.9916C16.593 20.3396 15.8197 20.5541 15.0007 20.6051V19.125H14.2507V20.6059C13.4317 20.5549 12.6581 20.3404 11.9599 19.9924L12.6982 18.7132L12.0488 18.3382L11.3085 19.62C10.644 19.1775 10.0729 18.6064 9.63037 17.9419L10.9155 17.2001L10.5405 16.5506L9.25837 17.2909C8.91037 16.5926 8.69587 15.819 8.64487 15H10.125V14.25H8.64412C8.69513 13.431 8.90963 12.6574 9.25763 11.9591L10.5398 12.6994L10.9148 12.0499L9.62963 11.3081C10.0725 10.6436 10.6436 10.0725 11.3077 9.62963Z" />
      <path d="M14.625 16.125C15.4523 16.125 16.125 15.4522 16.125 14.625C16.125 14.3475 16.0444 14.091 15.9124 13.8679L17.5151 12.2651L16.9849 11.7349L15.3825 13.3376C15.159 13.2056 14.9025 13.125 14.625 13.125C13.7977 13.125 13.125 13.7977 13.125 14.625C13.125 15.4522 13.7977 16.125 14.625 16.125ZM14.625 13.875C15.0386 13.875 15.375 14.2114 15.375 14.625C15.375 15.0386 15.0386 15.375 14.625 15.375C14.2114 15.375 13.875 15.0386 13.875 14.625C13.875 14.2114 14.2114 13.875 14.625 13.875Z" />
      <path d="M19.6072 8.51636L20.1375 7.98602L20.8874 8.73591L20.3571 9.26625L19.6072 8.51636Z" />
    </SvgIcon>
  );
}
export default PurchaseIcon;
