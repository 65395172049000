import React, {Suspense} from 'react';

const suspenseHoc = (WrapComponent) => {
  function HocContent() {
    return (
      <Suspense
        fallback={
          <div
            className={`loader loader-${
              window.localStorage.theme ? window.localStorage.theme : 'light'
            }`}
            data-text="Digital Waiter"
          >
            Digital Waiter
          </div>
        }
      >
        <WrapComponent />
      </Suspense>
    );
  }
  return HocContent;
};

export default suspenseHoc;
